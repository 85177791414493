import Logo10Education from '../../assets/logos/Logo10Education.jpg';
import LogoBKDC from '../../assets/logos/LogoBKDC.png';
import LogoCKGEAR from '../../assets/logos/LogoCKGEAR.jpg';
import LogoECSE from '../../assets/logos/LogoECSE.png';
import LogoEdu2Review from '../../assets/logos/LogoEdu2Review.png';
import LogoFADELY from '../../assets/logos/LogoFADELY.png';
import LogoFuji from '../../assets/logos/LogoFuji.png';
import LogoGamedev from '../../assets/logos/LogoGamedev.png';
import LogoKOIKEYA from '../../assets/logos/LogoKOIKEYA.jpg';
import LogoMACs from '../../assets/logos/LogoMACs.png';
import LogoMOSEASY from '../../assets/logos/LogoMOSEASY.png';
import LogoMOX from '../../assets/logos/LogoMOX.png';
import LogoMrBrown from '../../assets/logos/LogoMrBrown.png';
import LogoPIF from '../../assets/logos/LogoPIF.png';
import LogoPLEC from '../../assets/logos/LogoPLEC.png';
import LogoTicklab from '../../assets/logos/LogoTicklab.png';
import LogoUTEC from '../../assets/logos/LogoUTEC.png';
import LogoVIO from '../../assets/logos/LogoVIO.png';
import Icon from '../Icon';

type LogoMappingProps = {
  logoName: string;
  logoStyles?: string;
};

const LogoMapping = ({ logoName, logoStyles }: LogoMappingProps) => {
  switch (logoName) {
    case 'ctct':
      return <Icon.LogoCTCT className={logoStyles} />;
    case 'gdsc-hcmut':
      return <Icon.LogoGDSC className={logoStyles} />;
    case 'gdsc-horizontal':
      return <Icon.LogoGDSCHorizontal className={logoStyles} />;
    case 'bkdc':
      return <img src={LogoBKDC} alt='BKDC' className={logoStyles} />;
    case 'utec':
      return <img src={LogoUTEC} alt='UTEC' className={logoStyles} />;
    case 'gamedev':
      return <img src={LogoGamedev} alt='UTEC' className={logoStyles} />;
    case 'macs':
      return <img src={LogoMACs} alt='MACs' className={logoStyles} />;
    case 'plec':
      return <img src={LogoPLEC} alt='PLEC' className={logoStyles} />;
    case 'tickLab':
      return <img src={LogoTicklab} alt='TickLab' className={logoStyles} />;
    case 'fuji':
      return <img src={LogoFuji} alt='Fuji' className={logoStyles} />;
    case 'ecse':
      return <img src={LogoECSE} alt='ECSE' className={logoStyles} />;
    case 'mox':
      return <img src={LogoMOX} alt='MOX' className={logoStyles} />;
    case 'pif':
      return <img src={LogoPIF} alt='PIF' className={logoStyles} />;
    case 'ck-gear':
      return <img src={LogoCKGEAR} alt='CKGear' className={logoStyles} />;
    case 'vio-tech':
      return <img src={LogoVIO} alt='VIO' className={logoStyles} />;
    case 'king-car':
      return <img src={LogoMrBrown} alt='King Car' className={logoStyles} />;
    case '10-education':
      return <img src={Logo10Education} alt='10 Education' className={logoStyles} />;
    case 'moseasy':
      return <img src={LogoMOSEASY} alt='MOSEASY' className={logoStyles} />;
    case 'fadely':
      return <img src={LogoFADELY} alt='Fadely' className={logoStyles} />;
    case 'koikeya':
      return <img src={LogoKOIKEYA} alt='Koikeya' className={logoStyles} />;
    case 'edu2review':
      return <img src={LogoEdu2Review} alt='Edu2Review' className={logoStyles} />;
    case 'fessior':
      return <Icon.LogoFessior className={`fill-[#0F2D85] ${logoStyles}`} />;
    case 'facebook':
      return <Icon.LogoFacebookBlue className={logoStyles} />;
    case 'discord':
      return <Icon.LogoDiscord className={`fill-[#0F2D85] ${logoStyles}`} />;
    case 'gmail':
      return <Icon.LogoGmail className={`fill-[#0F2D85] ${logoStyles}`} />;
    case 'linkedin':
      return <Icon.LogoLinkedin className={`fill-[#0F2D85] ${logoStyles}`} />;
    case 'bookDonation-communication':
      return <Icon.CommunicationIcon className={logoStyles} />;
    case 'bookDonation-contentpaste':
      return <Icon.ContentPaste className={logoStyles} />;
    default:
      return <Icon.LogoCTCT className={logoStyles} />;
  }
};

export default LogoMapping;
