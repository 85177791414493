import './index.css';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

import { Pagination } from '../../../components';
import BookFairCheckinModal from '../../../components/Modal/BookFairCheckinModal';
import { Page, Wrapper } from '../../../layout';
import BookFairService from '../../../service/bookFair.service';

const BookFairCheckin = () => {
  const queryClient = useQueryClient();

  const [recipientPage, setRecipientPage] = useState(1);
  const [studentId, setStudentId] = useState('');
  const [checkinModal, setCheckinModal] = useState(false);

  const recipientQuery = useQuery({
    queryKey: ['admin-bookfair-checkin-list', recipientPage],
    queryFn: async () => {
      try {
        const response = await BookFairService.getCheckinList({
          pageNumber: recipientPage,
          pageSize: 10,
        });

        return response.data;
      } catch {
        toast.error('Lỗi khi lấy thông tin những người đã checkin');
      }
    },
  });

  const checkIn = useMutation({
    mutationFn: async (studentInfo: { studentId: string }) => {
      await BookFairService.checkinBookfair(studentInfo);
    },
    onSuccess: () => {
      toast.success(`Checkin thành công`);
      queryClient.invalidateQueries(['admin-bookfair-checkin-list']);
      setRecipientPage(1);
    },
    onError: (error: any) => {
      if (error instanceof AxiosError && error.response?.data.message) {
        return toast.error(error.response.data.message);
      }
      toast.error('Lỗi khi checkin, vui lòng kiểm tra lại thông tin');
    },
  });

  return (
    <Page>
      <Wrapper className='flex flex-1 flex-col'>
        <BookFairCheckinModal
          isOpen={checkinModal}
          handleOpen={setCheckinModal}
          studentId={studentId}
          runAction={checkIn.mutate}
        />

        <div className='w-full bg-[#4285F4]/90 py-4'>
          <h1 className='text-center text-sm font-bold text-white md:text-2xl 3xl:text-4xl'>
            Checkin sự kiện
          </h1>
        </div>
        <div className='w-full p-4'>
          {recipientQuery.isLoading ? (
            <>
              <p className='mb-5 w-full px-6 lg:px-8 3xl:px-10'>
                <Skeleton width={'100%'} baseColor='#9DCCFF' height={56} />
              </p>
              <p className='w-full px-6 lg:px-8 3xl:px-10'>
                {
                  <Skeleton
                    count={10}
                    className='my-2 box-content lg:my-4 3xl:my-6'
                    width={'100%'}
                    height={40}
                    baseColor='#9DCCFF'
                  />
                }
              </p>
            </>
          ) : (
            <>
              <div className='mt-4 h-full w-full rounded-lg bg-white px-8 py-2 lg:px-10 lg:py-4 3xl:px-12 3xl:py-6'>
                <form
                  className='flex flex-col gap-y-6'
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <p className='mt-4 flex flex-[2.5] text-base font-semibold lg:text-lg 3xl:text-xl'>
                    Check-In sự kiện
                  </p>

                  <div className='flex w-full flex-1 flex-row items-center justify-center gap-x-4'>
                    <div className='flex w-full flex-col items-start justify-center'>
                      <input
                        id='event-name'
                        className='flex w-full rounded-lg border border-[#CCC] p-1 text-xs font-medium
                            lg:p-3 lg:text-sm 3xl:p-5 3xl:text-base'
                        value={studentId}
                        placeholder='Nhập MSSV'
                        onChange={({ target }) => setStudentId(target.value)}
                      />
                    </div>
                    <div className='flex h-max w-fit flex-col items-center justify-center'>
                      <button
                        className={
                          'p-auto m-auto flex items-center rounded-lg border bg-[#4285F4]/80 px-1 font-medium transition-all duration-200 hover:bg-[#4285F4]'
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setCheckinModal(true);
                        }}
                        disabled={studentId.length === 0}
                      >
                        <p className='whitespace-nowrap p-1 text-xs text-white lg:p-3 lg:text-sm 3xl:p-5 3xl:text-base'>
                          Tra cứu
                        </p>
                      </button>
                    </div>
                  </div>

                  <p className='mt-4 flex flex-[2.5] text-base font-semibold lg:text-lg 3xl:text-xl'>
                    Danh sách người dùng đã Checkin
                  </p>

                  <ul className='mb-5 mt-2 flex w-full flex-col justify-between gap-2 text-sm md:gap-4 lg:text-base 3xl:text-xl'>
                    {recipientQuery.data?.registrations.map((recipient) => (
                      <li
                        key={recipient.studentId}
                        className='flex w-full flex-row items-center justify-between rounded-xl bg-slate-100 p-2 md:p-4'
                      >
                        <div className='flex w-[50%] flex-col gap-[0.25rem] lg:w-[70%]'>
                          <p className='font-semibold'>
                            {recipient.familyAndMiddleName} {recipient.givenName}
                          </p>
                          <p>
                            Email: {recipient.email} - MSSV: {recipient.studentId}
                          </p>
                        </div>
                        <p className='hidden font-medium md:block'>
                          {new Date(recipient.checkedInAt).toLocaleString()}
                        </p>
                      </li>
                    ))}
                  </ul>
                  <Pagination
                    pageSize={10}
                    totalCount={recipientQuery.data?.total ?? 0}
                    currentPage={recipientPage}
                    onPageChange={setRecipientPage}
                  />
                </form>
              </div>
            </>
          )}
        </div>
      </Wrapper>
    </Page>
  );
};

export default BookFairCheckin;
