import { FC, memo } from 'react';

import { BookFacultyType, FACULTY_TO_NAME_MAP } from '../../types';
import Icon from '../Icon';

interface BookFairFacultyTagProps {
  faculty: BookFacultyType;
  onRemoved: (faculty: BookFacultyType) => void;
}

const BookFairFacultyTag: FC<BookFairFacultyTagProps> = ({ faculty, onRemoved }) => {
  return (
    <div className='flex items-center gap-x-4 rounded-lg bg-[#0b2878] px-2 py-1 md:px-3 md:py-2'>
      <p className='text-sm text-white md:text-base'>{FACULTY_TO_NAME_MAP[faculty]}</p>
      <button onClick={() => onRemoved(faculty)}>
        <Icon.CloseIcon className='h-3 w-3 md:h-5 md:w-5' fill='white' />
      </button>
    </div>
  );
};

export default memo(BookFairFacultyTag);
