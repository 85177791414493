import { Dialog, Transition, Tab } from '@headlessui/react';
import { FC, FormEvent, Fragment, MouseEventHandler, useState } from 'react';
import { useDebounce } from 'usehooks-ts';

import { BOOK_FACULTY_OPTIONS, BookFacultyType } from '../../types';
import Icon from '../Icon';

interface BookfairFacultyModalProps {
  isModalOpen: boolean;
  onClose: (chosenFaculties?: BookFacultyType[]) => void;
  initialFaculties: BookFacultyType[];
}

const FACULTY_MODAL_DEBOUNCE_TIME_MS = 500;

const BookfairFacultyModal: FC<BookfairFacultyModalProps> = ({
  isModalOpen,
  onClose,
  initialFaculties,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, FACULTY_MODAL_DEBOUNCE_TIME_MS);

  const handleModalDismiss = () => {
    onClose();
  };

  const handleApplySelection = (formEvent: FormEvent<HTMLFormElement>) => {
    formEvent.preventDefault();

    const formData = Object.fromEntries(new FormData(formEvent.currentTarget));

    const selectedFaculties = Object.keys(formData) as BookFacultyType[];

    const sortedFaculties = BOOK_FACULTY_OPTIONS.filter((faculty) =>
      selectedFaculties.includes(faculty.value)
    ).map((faculty) => faculty.value);

    onClose(sortedFaculties);
  };

  const handleClickCloseButton: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    onClose();
  };

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={handleModalDismiss}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all'>
                <form onSubmit={handleApplySelection}>
                  <header className='mb-6 flex items-center justify-between'>
                    <Dialog.Title
                      as='h2'
                      className='text-2xl font-semibold leading-6 text-[#0b2878]'
                    >
                      Phân loại
                    </Dialog.Title>
                    <button onClick={handleClickCloseButton}>
                      <Icon.CloseIcon width={20} height={20} />
                    </button>
                  </header>

                  <Tab.Group>
                    <Tab.List className={'flex items-center justify-between'}>
                      <Tab
                        className={
                          'relative translate-y-[1px] rounded-t-lg border border-[#0b2878] border-b-white px-6 py-1'
                        }
                      >
                        <div className='absolute -left-[1px] -bottom-[1px] h-[1px] w-[1px] bg-[#0b2878]' />
                        <div className='absolute -right-[1px] -bottom-[1px] h-[1px] w-[1px] bg-[#0b2878]' />
                        <p className='text-base font-semibold text-[#0b2878]'>Khoa</p>
                      </Tab>
                      <div className='relative h-7 w-1/2 px-2 pb-1'>
                        <Icon.Search
                          width={12}
                          height={12}
                          fill='#0b2878'
                          className='absolute top-1/2 -translate-y-1/2'
                        />
                        <input
                          className='h-full w-full border-b border-[#6c7dad] pl-5 text-xs text-[#0b2878] placeholder-[#6c7dad] focus:outline-none'
                          value={searchTerm}
                          onChange={(event) => setSearchTerm(event.target.value)}
                          placeholder='Tìm kiếm theo khoa'
                        />
                      </div>
                    </Tab.List>
                    <Tab.Panels>
                      <Tab.Panel
                        className={
                          'h-48 overflow-scroll rounded-b-lg rounded-tr-lg border border-[#0b2878] px-3 py-3'
                        }
                      >
                        <ul>
                          {BOOK_FACULTY_OPTIONS.map(
                            (faculty) =>
                              faculty.label
                                .toLowerCase()
                                .includes(debouncedSearchTerm.toLowerCase()) && (
                                <li key={faculty.value} className='flex items-center gap-x-2'>
                                  <input
                                    type='checkbox'
                                    id={`bookfair-faculty-${faculty.value}`}
                                    name={faculty.value}
                                    className='accent-[#0b2878]'
                                    defaultChecked={initialFaculties.includes(faculty.value)}
                                  />
                                  <label
                                    htmlFor={`bookfair-faculty-${faculty.value}`}
                                    className='text-[#0b2878]'
                                  >
                                    {faculty.label}
                                  </label>
                                </li>
                              )
                          )}
                        </ul>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>

                  <div className='mt-6 flex w-full flex-row items-center justify-end gap-x-6'>
                    <button
                      type='submit'
                      className='inline-flex justify-center rounded-[20px] border border-transparent
                    bg-[#0b2878] px-4 py-2 focus:outline-none disabled:opacity-75'
                    >
                      <p className='text-base font-semibold text-white'>Áp dụng</p>
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default BookfairFacultyModal;
