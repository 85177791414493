import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Icon from '../Icon';

const BookFairMenuBox = () => {
  const [isToggle, setIsToggle] = useState(false);
  const { pathname } = useLocation();

  return (
    <div className='relative'>
      <button
        onClick={() => setIsToggle(true)}
        className='flex h-fit w-fit items-center space-x-2 rounded-xl bg-[#E3F2FD] p-1 hover:opacity-70 md:px-3'
      >
        <Icon.MenuListIcon fill='black' className='h-8 w-8' />
        <p className='hidden font-bold md:block lg:text-xl 2xl:text-xl'>Chi tiết</p>
      </button>
      <div
        className={`absolute top-0 right-0 z-10 flex w-[254px] flex-col rounded-xl bg-white px-4 pt-4 pb-7 shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] transition-all duration-200 md:w-[278px] md:px-5 md:pt-5 md:pb-9 lg:left-0 xl:w-[360px] ${
          isToggle ? 'translate-x-0' : 'translate-x-[400px] lg:translate-x-[-1000px]'
        }`}
      >
        <button
          onClick={() => setIsToggle(false)}
          className='absolute right-3 top-5 flex h-6 w-6 items-center justify-center rounded border border-black hover:opacity-70 md:right-5 md:top-6'
        >
          <Icon.ChevronLeft fill='black' className='h-4 w-4 rotate-180 lg:rotate-0' />
        </button>
        <p className='mb-4 text-xl font-semibold text-[#27327D] xl:mb-8 xl:text-2xl'>
          Thông tin sự kiện
        </p>
        <div className='flex flex-col space-y-2 xl:space-y-4'>
          <Link to={'/events/sach-cu-tri-thuc-moi'} className='group flex items-center space-x-2'>
            <Icon.BookmarkIcon
              className={`h-5 w-5 fill-black group-hover:fill-[#2F327D] md:h-6 md:w-6 ${
                pathname.endsWith('/events/sach-cu-tri-thuc-moi') && '!fill-[#2F327D]'
              }`}
            />
            <p
              className={`font-medium hover:text-[#2F327D] md:text-xl ${
                pathname.endsWith('/events/sach-cu-tri-thuc-moi')
                  ? 'text-[#2F327D]'
                  : 'text-[#696984]'
              }`}
            >
              Giới thiệu chung
            </p>
          </Link>
          <Link
            to={'/events/sach-cu-tri-thuc-moi/books'}
            className='group flex items-center space-x-2'
          >
            <Icon.Exercise
              className={`h-5 w-5 fill-black group-hover:fill-[#2F327D] md:h-6 md:w-6 ${
                pathname.endsWith('/events/sach-cu-tri-thuc-moi/books') && '!fill-[#2F327D]'
              }`}
            />
            <p
              className={`font-medium hover:text-[#2F327D] md:text-xl ${
                pathname.endsWith('/events/sach-cu-tri-thuc-moi/books')
                  ? 'text-[#2F327D]'
                  : 'text-[#696984]'
              }`}
            >
              Sách
            </p>
          </Link>
          <Link
            to={'/events/sach-cu-tri-thuc-moi/clubs'}
            className='group flex items-center space-x-2'
          >
            <Icon.AboutUs
              className={`h-5 w-5 fill-black group-hover:fill-[#2F327D] md:h-6 md:w-6 ${
                pathname.endsWith('/events/sach-cu-tri-thuc-moi/clubs') && '!fill-[#2F327D]'
              }`}
            />
            <p
              className={`font-medium hover:text-[#2F327D] md:text-xl ${
                pathname.endsWith('/events/sach-cu-tri-thuc-moi/clubs')
                  ? 'text-[#2F327D]'
                  : 'text-[#696984]'
              }`}
            >
              CLB, Đội nhóm
            </p>
          </Link>
          <Link
            to={'/events/sach-cu-tri-thuc-moi/sponsors'}
            className='group flex items-center space-x-2'
          >
            <Icon.AccountBalanceWallet
              className={`h-5 w-5 fill-black group-hover:fill-[#2F327D] md:h-6 md:w-6 ${
                pathname.endsWith('/events/sach-cu-tri-thuc-moi/sponsors') && '!fill-[#2F327D]'
              }`}
            />
            <p
              className={`font-medium hover:text-[#2F327D] md:text-xl ${
                pathname.endsWith('/events/sach-cu-tri-thuc-moi/sponsors')
                  ? 'text-[#2F327D]'
                  : 'text-[#696984]'
              }`}
            >
              Nhà tài trợ
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BookFairMenuBox;
