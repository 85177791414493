import { ChangeEvent, FC, useState } from 'react';

import { useDebounce } from '../../hooks';
import { BookFacultyType } from '../../types';
import { TBookFairSearchParams } from '../../types/bookFair';
import Icon from '../Icon';
import BookfairFacultyModal from '../Modal/BookfairFacultyModal';

import BookFairFacultyTag from './FacultyTag';

interface BookFairMarketPlaceSearchBarProps {
  onChange: (newSearchParam: TBookFairSearchParams) => void;
}

export const DEFAULT_SEARCH_PARAMS: TBookFairSearchParams = {
  title: '',
  faculties: [],
};

const ON_SEARCH_DEBOUNCE_TIME_MS = 1000;

const BookFairMarketPlaceSearchBar: FC<BookFairMarketPlaceSearchBarProps> = ({ onChange }) => {
  const [searchParams, setSearchParams] = useState<TBookFairSearchParams>(DEFAULT_SEARCH_PARAMS);
  const [isFacultyModalOpen, setIsFacultyModalOpen] = useState<boolean>(false);

  const debouncedOnChange = useDebounce(onChange, ON_SEARCH_DEBOUNCE_TIME_MS);

  const removeFaculty = (facultyToRemove: BookFacultyType) => {
    const newFaculties = searchParams.faculties.filter((faculty) => faculty !== facultyToRemove);
    setSearchParams({ ...searchParams, faculties: newFaculties });
    debouncedOnChange({ ...searchParams, faculties: newFaculties });
  };

  const removeAllFaculty = () => {
    const newFaculties: BookFacultyType[] = [];
    setSearchParams({ ...searchParams, faculties: newFaculties });
    debouncedOnChange({ ...searchParams, faculties: newFaculties });
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newTitle = event.target.value;
    setSearchParams({ ...searchParams, title: newTitle });
    debouncedOnChange({ ...searchParams, title: newTitle });
  };

  const handleCloseFacultyModal = (chosenFaculties?: BookFacultyType[]) => {
    setIsFacultyModalOpen(false);

    if (!chosenFaculties) {
      return;
    }

    setSearchParams({ ...searchParams, faculties: chosenFaculties });
    debouncedOnChange({ ...searchParams, faculties: chosenFaculties });
  };

  const handleOpenFacultyModal = () => setIsFacultyModalOpen(true);

  return (
    <>
      <BookfairFacultyModal
        isModalOpen={isFacultyModalOpen}
        onClose={handleCloseFacultyModal}
        initialFaculties={searchParams.faculties}
      />
      {/* Mobile version of search bar */}
      <div className='w-full md:hidden'>
        <h1 className='text-2xl font-medium'>
          Hãy tìm kiếm quyển sách phù hợp với bạn ngay nào!!!
        </h1>
        <br />
        <div className='flex items-center gap-x-2'>
          <div className='relative h-12 flex-1'>
            <button className='absolute left-4 top-1/2 -translate-y-1/2'>
              <Icon.Search width={20} height={20} fill='#C4C4C4' />
            </button>
            <input
              className='h-full w-full rounded-lg bg-[#C4C4C4] bg-opacity-20 px-12 placeholder-[#C4C4C4]'
              placeholder='Tìm kiếm'
              value={searchParams.title}
              onChange={handleTextChange}
            />
          </div>
          <button className='flex items-center gap-x-2 px-2' onClick={handleOpenFacultyModal}>
            <Icon.FilterIcon width={20} height={20} fill='#000AFF' />
            <p className='text-[#000AFF]'>Phân loại</p>
          </button>
        </div>
        <br />
        {searchParams.faculties.length > 0 && (
          <>
            <div className='flex items-center justify-between'>
              <p className='font-medium'>Khoa đã chọn</p>
              <button className='text-sm underline' onClick={removeAllFaculty}>
                Huỷ áp dụng
              </button>
            </div>
            <ul className='flex-center flex flex-wrap gap-y-2 gap-x-2 pt-2'>
              {searchParams.faculties.map((faculty) => (
                <li key={faculty}>
                  <BookFairFacultyTag faculty={faculty} onRemoved={removeFaculty} />
                </li>
              ))}
            </ul>
            <br />
          </>
        )}
      </div>
      {/* Desktop version of search bar */}
      <div className='hidden md:flex md:flex-col md:gap-y-4'>
        <div className='flex w-full items-center gap-x-7 rounded-lg bg-[#656C73] bg-opacity-10 px-8 py-6'>
          <p className='text-2xl font-semibold text-[#010514]'>Tìm kiếm</p>
          <div className='relative h-12 flex-1'>
            <button className='absolute left-4 top-1/2 -translate-y-1/2'>
              <Icon.Search width={20} height={20} fill='black' />
            </button>
            <input
              className='h-full w-full rounded-lg px-12 placeholder-gray-500 shadow-[0px_18px_47px_rgba(47,50,125,0.1)]'
              placeholder='Tìm kiếm cuốn sách của bạn...'
              value={searchParams.title}
              onChange={handleTextChange}
            />
            <button
              className='absolute right-4 top-1/2 -translate-y-1/2'
              onClick={handleOpenFacultyModal}
            >
              <Icon.Tune width={20} height={20} fill='black' />
            </button>
          </div>
        </div>
        <br />
        {searchParams.faculties.length > 0 && (
          <div className='flex items-center gap-x-4'>
            <p>Khoa đã chọn:</p>
            <ul className='flex flex-wrap items-center gap-x-4 gap-y-2'>
              {searchParams.faculties.map((faculty) => (
                <li key={faculty}>
                  <BookFairFacultyTag faculty={faculty} onRemoved={removeFaculty} />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <br />
    </>
  );
};

export default BookFairMarketPlaceSearchBar;
