import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { Icon } from '../../../../../components';
import LogoMapping from '../../../../../components/BookFair/LogoMapping';
import ParagraphWithImg from '../../../../../components/Content/ParagraphWithImg';
import PartnerIntroLayout from '../../../../../components/Layout/PartnerIntroLayout';
import { PartnersIntroductionData } from '../../../../../constants/bookFair';

const SponsorsIntroduction = () => {
  const params = useParams();
  const navigate = useNavigate();
  const clubData =
    params.sponsorId &&
    (params.sponsorId === 'vio-tech' ||
      params.sponsorId === 'king-car' ||
      params.sponsorId === 'moseasy' ||
      params.sponsorId === 'fadely' ||
      params.sponsorId === 'koikeya' ||
      params.sponsorId === 'edu2review' ||
      params.sponsorId === '10-education')
      ? PartnersIntroductionData[params.sponsorId || '']
      : undefined;

  console.log(params.sponsorId, clubData);

  useEffect(() => {
    if (!clubData) {
      navigate('/404');
    }
  }, [clubData, navigate]);

  if (!clubData) {
    return <></>;
  }

  return (
    <PartnerIntroLayout
      bannerImg={clubData.bannerImg}
      bannerImgPlaceholder={clubData.bannerImgPlaceholder}
      title={clubData.title}
    >
      <div className='md:px-12 lg:px-24 2xl:px-32 3xl:px-40'>
        <Link
          to={'/events/sach-cu-tri-thuc-moi/sponsors'}
          className='flex h-fit w-fit items-center space-x-2 rounded-xl bg-[#E3F2FD] px-2 py-1 hover:opacity-70 md:px-3'
        >
          <Icon.ChevronLeft fill='black' className='h-3 w-3 md:h-4 md:w-4' />
          <p className='text-sm font-bold md:text-base lg:text-xl 2xl:text-xl'>Nhà tài trợ</p>
        </Link>
      </div>
      <div className='relative'>
        <ParagraphWithImg
          title={clubData.name || ''}
          titleStyles='text-[#4285f4]'
          imageStyles='aspect-[70/40] lg:aspect-[60/50] xl:aspect-[70/40]'
          image={clubData.descImg}
          imagePlaceholder={clubData.descImgPlaceholder}
          imageAlt="Club's description"
        >
          <b>Hình thức tài trợ: </b>
          {clubData.sponsor_type}
          <br />
          {params.sponsorId === 'edu2review'
            ? clubData.descText.split('\n').map((line: string, index: number) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : clubData.descText}
        </ParagraphWithImg>
        <div className='absolute bottom-[-12px] right-[-12px] h-[136px] w-[70%] rounded-[20px] bg-[#E3F2FD] md:top-[-120px] md:right-[-20px] md:h-[70%] md:w-[30%] md:rounded-none lg:top-[-132px] 2xl:top-[-140px]' />
      </div>
      {clubData.contact && clubData.activityText !== '' && (
        <div className='mt-7 flex w-full flex-col-reverse items-start justify-between gap-8 md:mt-12 md:flex-row md:px-12 lg:gap-12 lg:px-24 2xl:gap-[56px] 2xl:px-32 3xl:px-40'>
          <div className='relative w-full md:w-[40%]'>
            <div className='absolute top-[-12px] right-[-12px] h-[70%] w-full rounded-[20px] bg-[#E3F2FD] md:w-[70%] lg:top-[-20px] lg:right-[-20px] lg:w-full' />
            <div className='relative z-[1] flex w-[100%] flex-col justify-between space-y-10 rounded-[36px] border border-[#4285f4] bg-white px-5 py-7 md:space-y-7 lg:px-7 lg:py-9 xl:space-y-10 2xl:h-[400px] 2xl:px-10 2xl:py-12'>
              <LogoMapping logoName={clubData.logo} logoStyles={clubData.logoStyles} />
              <div className='flex flex-col'>
                <h3 className='font-semibold leading-relaxed xl:text-xl 3xl:text-2xl'>
                  Thông tin liên hệ
                </h3>
                <div className='mt-1 text-justify text-sm font-medium leading-snug text-[#696984] md:mt-3 xl:text-base 3xl:text-xl'>
                  <p className='text-inherit'>
                    Email:{' '}
                    <a href='mailto:admin@fessior.com' target='_blank' rel='noreferrer'>
                      {clubData.contact.email}
                    </a>
                  </p>
                  <p className='text-inherit'>
                    Fanpage:{' '}
                    <a
                      href={`https://www.${clubData.contact.fanpage}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {clubData.contact.fanpage}
                    </a>
                  </p>
                </div>

                <div className='mt-6 flex flex-row items-center gap-x-2 xl:gap-x-3'>
                  {clubData.contact.fanpage && (
                    <a
                      href={`https://www.${clubData.contact.fanpage}`}
                      target='_blank'
                      rel='noreferrer'
                      className='rounded-lg bg-[#4285F4] p-1 md:p-2'
                    >
                      <Icon.LogoFacebook className='h-auto w-[28px]' fill='white' />
                    </a>
                  )}
                  {clubData.contact.email && (
                    <a
                      href={`mailto:${clubData.contact.email}`}
                      target='_blank'
                      rel='noreferrer'
                      className='rounded-lg bg-[#4285F4] p-1 md:p-2'
                    >
                      <Icon.LogoGmail className='h-auto w-[28px]' fill='white' />
                    </a>
                  )}
                  {clubData.contact.discord && (
                    <a
                      href={`https://${clubData.contact.discord}`}
                      target='_blank'
                      rel='noreferrer'
                      className='rounded-lg bg-[#4285F4] p-1 md:p-2'
                    >
                      <Icon.LogoDiscord className='h-auto w-[28px]' fill='white' />
                    </a>
                  )}
                  {clubData.contact.linkedin && (
                    <a
                      href={`https://www.${clubData.contact.linkedin}`}
                      target='_blank'
                      rel='noreferrer'
                      className='rounded-lg bg-[#4285F4] p-1 md:p-2'
                    >
                      <Icon.LogoLinkedin className='h-auto w-[28px]' fill='white' />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-center gap-2 md:max-w-[56%] lg:gap-4 2xl:gap-5'>
            <h2 className='text-start text-xl font-semibold text-[#4285f4] xl:text-[24px] 2xl:text-[28px]'>
              Hoạt động
            </h2>
            <p className='z-[1] text-start leading-7 text-[#696984] md:leading-7 lg:leading-9 2xl:leading-10'>
              {clubData.activityText}
            </p>
          </div>
        </div>
      )}
    </PartnerIntroLayout>
  );
};

export default SponsorsIntroduction;
