import { Carousel } from 'react-responsive-carousel';

import { CarouselIndicator } from '../../components';
import { SocialMediaData as data } from '../../data/CarouselData';
import { useWindowDimensions } from '../../hooks';

const SocialMediaCarousel = () => {
  const { width } = useWindowDimensions();
  return (
    <>
      {width > 768 ? (
        <div className='flex h-max w-full flex-row items-start justify-start md:gap-8 lg:gap-12 2xl:gap-[56px]'>
          {data.map((item, index) => (
            <a
              className='flex h-full w-[33%] cursor-pointer flex-col items-start justify-start gap-y-2 rounded-[20px] p-7 shadow-xl md:p-3 lg:gap-y-3 lg:py-5 lg:px-4 2xl:py-7 2xl:px-5'
              key={`new_${index}`}
              target='_blank'
              href={item.linkTo}
              rel='noreferrer'
            >
              <div className='z-[1] flex h-[12.5rem] w-[100%] flex-col items-center justify-center xl:h-[15rem]'>
                <item.logo className='h-[5rem] w-[180px] md:w-[200px] lg:h-[7rem] xl:w-[228px] 2xl:w-[240px]' />
              </div>

              <p className='text-start font-medium leading-6 text-[#252641] lg:leading-8 2xl:leading-9'>
                {item.title}
              </p>

              <p className='text-[14px] font-normal leading-6 lg:text-[16px] lg:leading-7 2xl:text-[18px] 2xl:leading-8'>
                {item.description}
              </p>
            </a>
          ))}
        </div>
      ) : (
        <div className='flex h-max w-full items-center justify-center'>
          <Carousel
            showThumbs={false}
            showStatus={false}
            preventMovementUntilSwipeScrollTolerance={true}
            swipeScrollTolerance={50}
            swipeable
            autoPlay
            infiniteLoop
            interval={10000}
            transitionTime={1000}
            showArrows={false}
            renderIndicator={CarouselIndicator}
            stopOnHover
            className='flex h-max w-full flex-col items-center justify-center gap-y-5 bg-transparent'
          >
            {data.map((item, index) => (
              <div className='flex h-full w-full justify-center pb-10' key={`new_${index}`}>
                <a
                  target='_blank'
                  href={item.linkTo}
                  className='flex w-[calc(100%-40px)] cursor-pointer flex-col items-start justify-center gap-y-2 rounded-[20px] p-3 shadow-xl'
                  rel='noreferrer'
                >
                  <div className='z-[1] flex h-[12.5rem] w-[100%] flex-col items-center justify-center xl:h-[15rem]'>
                    <item.logo className='h-[5rem] w-[180px] md:w-[200px] lg:h-[7rem] xl:w-[228px] 2xl:w-[240px]' />
                  </div>

                  <p className='text-start text-[24px] font-medium leading-7 text-[#252641]'>
                    {item.title}
                  </p>

                  <p className='text-start text-[16px] font-normal leading-7 text-[#696984]'>
                    {item.description}
                  </p>
                </a>
              </div>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default SocialMediaCarousel;
