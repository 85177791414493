const AchievementData = [
  {
    id: 1,
    number: '14K+',
    title: 'Sinh viên',
  },
  {
    id: 2,
    number: '07',
    title: 'Môn học',
  },
  {
    id: 3,
    number: '30-80',
    title: 'Sinh viên tham gia / lớp học',
  },
  {
    id: 4,
    number: '50-60',
    title: 'Lớp học mỗi kỳ',
  },
];

export default AchievementData;
