import BKDCActivityPlaceholder from '../assets/images/BKDCActivity-placeholder.jpg';
import BKDCActivity from '../assets/images/BKDCActivity.jpg';
import BKDCDescPlaceholder from '../assets/images/BKDCDesc-placeholder.jpg';
import BKDCDesc from '../assets/images/BKDCDesc.jpg';
import BKDCIntroPlaceholder from '../assets/images/BKDCIntro-placeholder.jpg';
import BKDCIntro from '../assets/images/BKDCIntro.jpg';
import CKGearActivityPlaceholder from '../assets/images/CKGearActivity-placeholder.jpg';
import CKGearActivity from '../assets/images/CKGearActivity.jpg';
import CKGearDescPlaceholder from '../assets/images/CKGearDesc-placeholder.jpg';
import CKGearDesc from '../assets/images/CKGearDesc.jpg';
import CKGearIntroPlaceholder from '../assets/images/CKGearIntro-placeholder.jpg';
import CKGearIntro from '../assets/images/CKGearIntro.jpg';
import ECSEActivityPlaceholder from '../assets/images/ECSEActivity-placeholder.jpg';
import ECSEActivity from '../assets/images/ECSEActivity.jpg';
import ECSEDescPlaceholder from '../assets/images/ECSEDesc-placeholder.jpg';
import ECSEDesc from '../assets/images/ECSEDesc.jpg';
import ECSEIntroPlaceholder from '../assets/images/ECSEIntro-placeholder.jpg';
import ECSEIntro from '../assets/images/ECSEIntro.jpg';
import Edu2ReviewDescPlaceholder from '../assets/images/Edu2ReviewDesc-placeholder.png';
import Edu2ReviewDesc from '../assets/images/Edu2ReviewDesc.png';
import Edu2ReviewIntroPlaceholder from '../assets/images/Edu2ReviewIntro-placholder.png';
import Edu2ReviewIntro from '../assets/images/Edu2ReviewIntro.png';
import Education10DescPlaceholder from '../assets/images/Education10Desc-placeholder.jpg';
import Education10Desc from '../assets/images/Education10Desc.jpg';
import Education10IntroPlaceholder from '../assets/images/Education10Intro-placeholder.png';
import Education10Intro from '../assets/images/Education10Intro.png';
import FadelyDescPlaceholder from '../assets/images/FadelyDesc-placeholder.jpg';
import FadelyDesc from '../assets/images/FadelyDesc.jpg';
import FadelyIntroPlaceholder from '../assets/images/FadelyIntro-placeholder.jpg';
import FadelyIntro from '../assets/images/FadelyIntro.jpg';
import FujiActivityPlaceholder from '../assets/images/FujiActivity-placeholder.jpg';
import FujiActivity from '../assets/images/FujiActivity.jpg';
import FujiDescPlaceholder from '../assets/images/FujiDesc-placeholder.jpg';
import FujiDesc from '../assets/images/FujiDesc.jpg';
import FujiIntroPlaceholder from '../assets/images/FujiIntro-placeholder.png';
import FujiIntro from '../assets/images/FujiIntro.png';
import GamedevActivityPlaceholder from '../assets/images/GamedevActivity-placeholder.jpg';
import GamedevActivity from '../assets/images/GamedevActivity.jpg';
import GamedevDescPlaceholder from '../assets/images/GamedevDesc-placeholder.jpg';
import GamedevDesc from '../assets/images/GamedevDesc.jpg';
import GamedevIntroPlaceholder from '../assets/images/GamedevIntro-placeholder.png';
import GamedevIntro from '../assets/images/GamedevIntro.png';
import GDSCDescPlaceholder from '../assets/images/GDSCDesc-placeholder.png';
import GDSCDesc from '../assets/images/GDSCDesc.png';
import GDSCIntroPlaceholder from '../assets/images/GDSCIntro-placeholder.png';
import GDSCIntro from '../assets/images/GDSCIntro.png';
import KingCarDescPlaceholder from '../assets/images/KingCarDesc-placeholder.jpg';
import KingCarDesc from '../assets/images/KingCarDesc.jpg';
import KingCarIntroPlaceholder from '../assets/images/KingCarIntro-placeholder.jpg';
import KingCarIntro from '../assets/images/KingCarIntro.jpg';
import KoikeyaDescPlaceholder from '../assets/images/KoikeyaDesc-placeholder.jpg';
import KoikeyaDesc from '../assets/images/KoikeyaDesc.jpg';
import KoikeyaIntroPlaceholder from '../assets/images/KoikeyaIntro-placeholder.jpg';
import KoikeyaIntro from '../assets/images/KoikeyaIntro.jpg';
import MACsActivityPlaceholder from '../assets/images/MACsActivity-placeholder.jpg';
import MACsActivity from '../assets/images/MACsActivity.jpg';
import MACsDescPlaceholder from '../assets/images/MACsDesc-placeholder.jpg';
import MACsDesc from '../assets/images/MACsDesc.jpg';
import MACsIntroPlaceholder from '../assets/images/MACsIntro-placeholder.jpg';
import MACsIntro from '../assets/images/MACsIntro.jpg';
import MoseasyDescPlaceholder from '../assets/images/MoseasyDesc-placeholder.jpg';
import MoseasyDesc from '../assets/images/MoseasyDesc.jpg';
import MoseasyIntroPlaceholder from '../assets/images/MoseasyIntro-placeholder.png';
import MoseasyIntro from '../assets/images/MoseasyIntro.png';
import MOXActivityPlaceholder from '../assets/images/MOXActivity-placeholder.jpg';
import MOXActivity from '../assets/images/MOXActivity.jpg';
import MOXDescPlaceholder from '../assets/images/MOXDesc-placeholder.jpg';
import MOXDesc from '../assets/images/MOXDesc.jpg';
import MOXIntroPlaceholder from '../assets/images/MOXIntro-placeholder.jpg';
import MOXIntro from '../assets/images/MOXIntro.jpg';
import PIFActivityPlaceholder from '../assets/images/PIFActivity-placeholder.jpg';
import PIFActivity from '../assets/images/PIFActivity.jpg';
import PIFDescPlaceholder from '../assets/images/PIFDesc-placeholder.jpg';
import PIFDesc from '../assets/images/PIFDesc.jpg';
import PIFIntroPlaceholder from '../assets/images/PIFIntro-placeholder.png';
import PIFIntro from '../assets/images/PIFIntro.png';
import PLECDescPlaceholder from '../assets/images/PLECDesc-placeholder.jpg';
import PLECDesc from '../assets/images/PLECDesc.jpg';
import PLECIntroPlaceholder from '../assets/images/PLECIntro-placeholder.jpg';
import PLECIntro from '../assets/images/PLECIntro.jpg';
import Activity1Placholder from '../assets/images/SCTTM_11-placeholder.jpg';
import Activity1 from '../assets/images/SCTTM_11.jpg';
import Activity2Placholder from '../assets/images/SCTTM_12-placeholder.jpg';
import Activity2 from '../assets/images/SCTTM_12.jpg';
import Activity3Placholder from '../assets/images/SCTTM_13-placeholder.jpg';
import Activity3 from '../assets/images/SCTTM_13.jpg';
import Activity4Placholder from '../assets/images/SCTTM_14-placeholder.jpg';
import Activity4 from '../assets/images/SCTTM_14.jpg';
import CTCTDescPlaceholder from '../assets/images/SCTTM_7-placeholder.jpg';
import CTCTDesc from '../assets/images/SCTTM_7.jpg';
import CTCTIntroPlaceholder from '../assets/images/SCTTM_8-placeholder.jpg';
import CTCTIntro from '../assets/images/SCTTM_8.jpg';
import TicklabActivityPlaceholder from '../assets/images/TicklabActivity-placeholder.jpg';
import TicklabActivity from '../assets/images/TicklabActivity.jpg';
import TickLabDescPlaceholder from '../assets/images/TickLabDesc-placeholder.jpg';
import TickLabDesc from '../assets/images/TickLabDesc.jpg';
import TickLabIntroPlaceholder from '../assets/images/TickLabIntro-placeholder.jpg';
import TickLabIntro from '../assets/images/TickLabIntro.jpg';
import UTECActivityPlaceholder from '../assets/images/UTECActivity-placeholder.jpg';
import UTECActivity from '../assets/images/UTECActivity.jpg';
import UTECDescPlaceholder from '../assets/images/UTECDesc-placeholder.jpg';
import UTECDesc from '../assets/images/UTECDesc.jpg';
import UTECIntroPlaceholder from '../assets/images/UTECIntro-placeholder.png';
import UTECIntro from '../assets/images/UTECIntro.png';
import VioDescPlaceholder from '../assets/images/VioDesc-placeholder.jpg';
import VioDesc from '../assets/images/VioDesc.jpg';
import VioIntroPlaceholder from '../assets/images/VioIntro-placeholder.jpg';
import VioIntro from '../assets/images/VioIntro.jpg';
import { Option } from '../components/Select';
import { TBookFairActivity, TBookFairPartner } from '../types/bookFair';

export const BookFairSponsorList: TBookFairPartner[] = [
  {
    name: 'Công nghệ Vio',
    link: 'vio-tech',
  },
  {
    name: 'King Car Việt Nam',
    link: 'king-car',
  },
  {
    name: '10 Education',
    link: '10-education',
  },
  {
    name: 'MOSEASY',
    link: 'moseasy',
  },
  {
    name: 'Bánh tráng Fadely',
    link: 'fadely',
  },
  {
    name: 'Koikeya',
    link: 'koikeya',
  },
  {
    name: 'Edu2Review và Studynow',
    link: 'edu2review',
  },
];

export const BookFairClubList: TBookFairPartner[] = [
  {
    name: 'Câu lạc bộ Chúng Ta Cùng Tiến - CTCT',
    link: 'ctct',
  },
  {
    name: "Blue Kingdom's Dancing Community - BKDC",
    link: 'bkdc',
  },
  {
    name: 'Câu lạc bộ Tiếng Anh Trường Đại học Bách khoa - UTEC',
    link: 'utec',
  },
  {
    name: 'GameDev Club',
    link: 'gamedev',
  },
  {
    name: 'Google Developer Student Club - HCMUT (GDSC HCMUT)',
    link: 'gdsc-hcmut',
  },
  {
    name: 'MACs - MECHANIACs Music Club',
    link: 'macs',
  },
  {
    name: 'CLB Tiếng Anh PlaY&LearN English Club - PLEC',
    link: 'plec',
  },
  {
    name: 'TickLab',
    link: 'tickLab',
  },
  {
    name: 'CLB Tiếng Nhật Fuji',
    link: 'fuji',
  },
  {
    name: 'Echo of CSE - ECSE',
    link: 'ecse',
  },
  {
    name: 'Môi Trường Xanh - MOX',
    link: 'mox',
  },
  {
    name: 'Pay It Forward Club  - PIF',
    link: 'pif',
  },
  {
    name: 'CK GEAR Team',
    link: 'ck-gear',
  },
];

export const WhiteLogoList: string[] = ['macs', 'bkdc'];

export const BookFairActivities: TBookFairActivity[] = [
  {
    title: '• Trao tặng 2 cuốn sách/sinh viên\n• Trao đổi sách đại cương nhận sách chuyên ngành',
    image: Activity1,
    placeholderImage: Activity1Placholder,
  },
  {
    title:
      '• Trao đổi sách đại cương/chuyên ngành nhận cây sen đá\n• Trao đổi sách đại cương/chuyên ngành nhận quà',
    image: Activity4,
    placeholderImage: Activity4Placholder,
  },
  {
    title: '• Gian hàng hỗ trợ học tập\n• Văn nghệ',
    image: Activity3,
    placeholderImage: Activity3Placholder,
  },
  {
    title:
      '• Gian hàng trò chơi, giới thiệu các Câu lạc bộ/ Đội/ Nhóm\n• Gian hàng các đơn vị đồng hành (vệ sinh máy tính, thư viện, BK English và các nhà tài trợ)',
    image: Activity2,
    placeholderImage: Activity2Placholder,
  },
];

export const SchoolYearOptions: Option[] = [
  {
    label: 'K18',
    value: 'K18',
  },
  {
    label: 'K19',
    value: 'K19',
  },
  {
    label: 'K20',
    value: 'K20',
  },
  {
    label: 'K21',
    value: 'K21',
  },
  {
    label: 'K22',
    value: 'K22',
  },
  {
    label: 'K23',
    value: 'K23',
  },
  {
    label: 'K24',
    value: 'K24',
  },
];

export const PartnersIntroductionData = {
  'gdsc-hcmut': {
    bannerImg: GDSCIntro,
    bannerImgPlaceholder: GDSCIntroPlaceholder,
    activityImg: '',
    activityImgPlaceholder: '',
    title: 'GDSC HCMUT',
    name: 'Google Developer Student Club HCMUT - GDSC HCMUT',
    descImg: GDSCDesc,
    descImgPlaceholder: GDSCDescPlaceholder,
    descText:
      'Google Developer Student Club HCMUT (GDSC HCMUT) là một CLB được hỗ trợ bởi Google Developers. GDSC HCMUT là nơi chào đón tất cả các bạn sinh viên yêu thích công nghệ phần mềm cùng học tập và phát triển. Với gần 5 năm hoạt động, GDSC HCMUT mang trong mình sứ mệnh truyền cảm hứng "học hỏi, kết nối, phát triển" nhằm hướng đến việc xây dựng một cộng đồng sinh viên cùng chia sẻ đam mê lập trình và phát triển các giải pháp công nghệ.',
    logo: 'gdsc-horizontal',
    logoStyles: 'w-full max-w-[492px]',
    contact: {
      email: 'admin@fessior.com',
      fanpage: 'facebook.com/dscxhcmut',
      discord: 'discord.gg/dscxhcmut',
      linkedin: 'linkedin.com/company/gdschcmut/mycompany/',
    },
    activityText:
      'Những hoạt động mà GDSC HCMUT thực hiện có thể được chia làm hai mảng chính: sự kiện và dự án công nghệ. Về sự kiện, hằng năm GDSC HCMUT đều tổ chức các buổi workshop, seminar, cuộc thi về công nghệ với mong muốn cung cấp cho các bạn sinh viên kiến thức nền tảng trong những lĩnh vực công nghệ thịnh hành hiện nay. Về các dự án, các thành viên GDSC HCMUT đã và đang ứng dụng những kiến thức đã học để phát triển, vận hành những sản phẩm đem lại lợi ích thiết thực cho cộng đồng. Một số dự án tiêu biểu có thể kể đến như GDSC URL Shortener; website giới thiệu và tổ chức gây quỹ cho Quỹ Học bổng Trần Văn Khê; GDSC Game; app trò chơi lớn được sử dụng cho OISP CAMP các năm 2022, 2021.',
  },
  ctct: {
    bannerImg: CTCTIntro,
    bannerImgPlaceholder: CTCTIntroPlaceholder,
    activityImg: '',
    activityImgPlaceholder: '',
    title: 'CTCT',
    name: 'Câu lạc bộ Chúng Ta Cùng Tiến - CTCT',
    descImg: CTCTDesc,
    descImgPlaceholder: CTCTDescPlaceholder,
    descText:
      'Chúng Ta Cùng Tiến là Câu lạc bộ học thuật được thành lập dưới sự quản lý của Trung tâm Hỗ trợ Sinh viên và Việc làm.\nVới khẩu hiệu "We Learn We Share", Chúng Ta Cùng Tiến luôn mang trong mình một trọng trách là tổ chức hoạt động vì lợi ích của sinh viên và kết nối, đồng hành cùng sinh viên.\nCLB là tập hợp của những cá nhân Vững mạnh kiến thức trong HỖ TRỢ HỌC TẬP; Tiên phong sáng tạo trong TRUYỀN THÔNG; Năng động thích ứng trong SỰ KIỆN; và Thân thiện, hài hòa trong NHÂN SỰ HẬU CẦN.',
    logo: 'ctct',
    logoStyles: 'w-full max-w-[492px] max-h-[120px]',
    contact: {
      email: 'chungtacungtienbk@gmail.com',
      fanpage: 'facebook.com/chungtacungtienbk',
      discord: '',
      linkedin: '',
    },
    activityText:
      'Các hoạt động nổi bật\n- Chương trình Hỗ trợ Tân sinh viên “Tiếp sức tới trường”\n- Chương trình “Trò chuyện cùng Tân sinh viên”\n- Chương trình “Sách cũ - Tri thức mới” : \n- Chương trình “Ôn tập, hệ thống kiến thức và thi thử các môn Đại cương”\n- Chương trình “Gia Sư Áo Xanh Bách Khoa”',
  },
  plec: {
    bannerImg: PLECIntro,
    bannerImgPlaceholder: PLECIntroPlaceholder,
    activityImg: '',
    activityImgPlaceholder: '',
    title: 'PLEC',
    name: 'CLB Tiếng Anh PlaY&LearN English Club - PLEC',
    descImg: PLECDesc,
    descImgPlaceholder: PLECDescPlaceholder,
    logo: 'plec',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'plec.fme@gmail.com',
      fanpage: 'facebook.com/playnlearnenglish',
      discord: '',
      linkedin: '',
    },
    activityText:
      '😳 Đặc biệt hơn, năm nay PLEC dự kiến sẽ có chuỗi video mới mang tên “PLEC-GoGo” (tạm dịch là: PLEC đi muôn nơi) hứa hẹn sẽ đưa các bạn sinh viên đến những nơi thật quen thuộc nhưng vô cùng thú vị.\n✅ Để hiểu rõ hơn thông tin chi tiết về clb, các bạn hãy like và follow fanpage PlaY&LearN English Club nhé. 🥰\n#SachcuTrithucmoi2024 #CTCT #PLEC',
    descText:
      '📌PlaY&LearN English Club đã thành lập được 13 năm cho đến nay và trải qua rất nhiều hoạt động hấp dẫn. Cụ thể hơn, PLEC không đơn thuần là clb học thuật mà còn là sân chơi đầy năng động và sáng tạo cho các bạn sinh viên với niềm đam mê tiếng Anh. Hằng năm, PLEC luôn tổ chức các sự kiện thường niên điển hình là workshop “Speak It Out” và “The Amazing Race”. Những sân chơi trên sẽ đem lại cho các bạn sinh viên cảm giác vui chơi bổ ích sau nhiều buổi học căng thẳng mà còn là nơi các bạn có thể trau dồi thêm tiếng Anh.',
  },
  bkdc: {
    bannerImg: BKDCIntro,
    bannerImgPlaceholder: BKDCIntroPlaceholder,
    activityImg: BKDCActivity,
    activityImgPlaceholder: BKDCActivityPlaceholder,
    title: 'BKDC',
    name: "Blue Kingdom's Dancing Community - BKDC",
    descImg: BKDCDesc,
    descImgPlaceholder: BKDCDescPlaceholder,
    logo: 'bkdc',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'bkdchcmut@gmail.com',
      fanpage: 'facebook.com/clbbkdc',
      discord: '',
      linkedin: '',
    },
    activityText: '',
    descText:
      "CLB Blue Kingdom's Dancing Community (BKDC) thuộc khoa Xây dựng trường Đại học Bách khoa là nơi sinh viên thể hiện đam mê nhảy múa và khám phá nhiều thể loại như hip-hop, waacking, afro. Không chỉ về nhảy, BKDC còn có team Media chuyên thiết kế, quay dựng video và sáng tạo nội dung. Dù chưa có kinh nghiệm, chỉ cần đam mê nhảy múa, edit, design đều có thể tham gia, phát triển kỹ năng và kết nối bạn bè. Hãy tham gia BKDC để trải nghiệm và tạo nên những bước nhảy, tác phẩm ấn tượng trong thời sinh viên năng động của bạn nhé!",
  },
  utec: {
    bannerImg: UTECIntro,
    bannerImgPlaceholder: UTECIntroPlaceholder,
    activityImg: UTECActivity,
    activityImgPlaceholder: UTECActivityPlaceholder,
    title: 'UTEC',
    name: 'Câu lạc bộ Tiếng Anh Trường Đại học Bách khoa - UTEC',
    descImg: UTECDesc,
    descImgPlaceholder: UTECDescPlaceholder,
    logo: 'utec',
    logoStyles: 'w-fit max-w-[492px] max-h-[80px]',
    contact: {
      email: 'hcmut.utec@gmail.com',
      fanpage: 'facebook.com/Ngoaingudhbachkhoa',
      discord: '',
      linkedin: '',
    },
    activityText: '',
    descText:
      'Là Câu lạc bộ Tiếng Anh chính thức của Trường Đại học Bách khoa, UTEC mong muốn đem đến một môi trường chuyên nghiệp, thân thiện và không kém phần thú vị cho các bạn có nhu cầu trau dồi và cải thiện khả năng tiếng Anh, cũng như phát triển kĩ năng xã hội.',
  },
  gamedev: {
    bannerImg: GamedevIntro,
    bannerImgPlaceholder: GamedevIntroPlaceholder,
    activityImg: GamedevActivity,
    activityImgPlaceholder: GamedevActivityPlaceholder,
    title: 'Gamedev',
    name: 'GameDev Club',
    descImg: GamedevDesc,
    descImgPlaceholder: GamedevDescPlaceholder,
    logo: 'gamedev',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'hcmut.gamedev.club@gmail.com',
      fanpage: 'facebook.com/hcmutGDC',
      discord: '',
      linkedin: '',
    },
    activityText: '',
    descText:
      'Ra đời năm 2020, GameDev Club là một sân chơi năng động, bổ ích cho các bạn sinh viên có niềm đam mê về lĩnh vực lập trình game. 🎮\nGameDev hoạt động với mục đích các thành viên có thể giao lưu, cùng học hỏi qua các buổi hướng dẫn, cùng nhau tạo ra những sản phẩm thiết thực phục vụ học tập và đời sống, có thêm những kiến thức bổ ích về ngành nghề và định hướng cho tương lai. 👾',
  },
  macs: {
    bannerImg: MACsIntro,
    bannerImgPlaceholder: MACsIntroPlaceholder,
    activityImg: MACsActivity,
    activityImgPlaceholder: MACsActivityPlaceholder,
    title: 'MACs',
    name: 'MACs - MECHANIACs Music Club',
    descImg: MACsDesc,
    descImgPlaceholder: MACsDescPlaceholder,
    logo: 'macs',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'mac.hcmut@gmail.com',
      fanpage: 'facebook.com/hremacclub',
      discord: '',
      linkedin: '',
    },
    activityText: '',
    descText:
      '🎸 "Cơ Khí là nghề, âm nhạc là đam mê! Đến với MACs-Mechaniacs, bạn sẽ khám phá một không gian tuyệt vời để vừa thể hiện tài năng âm nhạc, vừa kết nối với những người cùng sở thích. Cùng nhau, chúng ta sẽ tạo ra những giai điệu và kỷ niệm không thể nào quên!" 🎤',
  },
  tickLab: {
    bannerImg: TickLabIntro,
    bannerImgPlaceholder: TickLabIntroPlaceholder,
    activityImg: TicklabActivity,
    activityImgPlaceholder: TicklabActivityPlaceholder,
    title: 'TickLab',
    name: 'TickLab',
    descImg: TickLabDesc,
    descImgPlaceholder: TickLabDescPlaceholder,
    logo: 'tickLab',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'ticklab.vn@gmail.com',
      fanpage: 'facebook.com/ticklab.vn',
      discord: '',
      linkedin: '',
    },
    activityText: '',
    descText:
      'TickLab là một tổ chức sinh viên nơi các thành viên cùng nhau học tập, nghiên cứu và phát triển. Với hai trụ cột chính là Ban Nghiên cứu khoa học và Ban Phát triển dự án, TickLab là một môi trường lý tưởng để mỗi cá nhân không ngừng nâng cao kiến thức chuyên môn và rèn luyện kỹ năng mềm phù hợp với nguyện vọng và định hướng riêng, tạo tiền đề để các thành viên biến những ý tưởng thành hiện thực và sẵn sàng chinh phục những thử thách mới trong tương lai.',
  },
  fuji: {
    bannerImg: FujiIntro,
    bannerImgPlaceholder: FujiIntroPlaceholder,
    activityImg: FujiActivity,
    activityImgPlaceholder: FujiActivityPlaceholder,
    title: 'Fuji',
    name: 'CLB Tiếng Nhật Fuji',
    descImg: FujiDesc,
    descImgPlaceholder: FujiDescPlaceholder,
    logo: 'fuji',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'fujijapanesecorner@gmail.com',
      fanpage: 'facebook.com/FujiBKHCM',
      discord: '',
      linkedin: '',
    },
    activityText: '',
    descText:
      'Câu lạc bộ Tiếng Nhật FUJI BKHCM được thành lập bởi các sinh viên Trường Đại học Bách Khoa và Trường Đại học Khoa học xã hội và Nhân văn ĐHQG-HCM. Với mong muốn là cầu nối giúp các bạn sinh viên đến gần hơn với con người và đất nước Nhật Bản, FUJI hứa hẹn sẽ mang tới một màu sắc văn hóa thật đặc biệt, nơi mà mọi tình yêu dành cho xứ hoa anh đào được chào đón.',
  },
  ecse: {
    bannerImg: ECSEIntro,
    bannerImgPlaceholder: ECSEIntroPlaceholder,
    activityImg: ECSEActivity,
    activityImgPlaceholder: ECSEActivityPlaceholder,
    title: 'ECSE',
    name: 'Echo of CSE - ECSE',
    descImg: ECSEDesc,
    descImgPlaceholder: ECSEDescPlaceholder,
    logo: 'ecse',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'ecse.clbamnhac.hcmut@gmail.com',
      fanpage: 'facebook.com/ecse.hcmut',
      discord: '',
      linkedin: '',
    },
    activityText: '',
    descText:
      'ECSE - Echo of CSE, Câu lạc bộ Âm nhạc khoa Khoa học và Kỹ thuật Máy tính, là câu lạc bộ trực thuộc LCH Sinh viên khoa KH&KT Máy tính, chính vì vậy nên ECSE tham gia đồng hành vào hầu hết các sự kiện của Khoa KH&KT Máy tính, ngoài ra còn có các sự kiện của trường ĐH Bách khoa - ĐHQG-HCM. Ngoài ra, ECSE còn tổ chức các show âm nhạc nội bộ và cả show âm nhạc thường niên “Echo of our hearts”. Hiện tại, ECSE đang tổ chức đợt tuyển thành viên từ ngày 19/08/2024 - 05/09/2024, hãy nhanh tay ghé qua fanpage của ECSE để đăng ký trở thành một thành viên của ECSE nhé!',
  },
  mox: {
    bannerImg: MOXIntro,
    bannerImgPlaceholder: MOXIntroPlaceholder,
    activityImg: MOXActivity,
    activityImgPlaceholder: MOXActivityPlaceholder,
    title: 'MOX',
    name: 'Môi Trường Xanh - MOX',
    descImg: MOXDesc,
    descImgPlaceholder: MOXDescPlaceholder,
    logo: 'mox',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'clbmoitruongxanhbk@gmail.com',
      fanpage: 'facebook.com/clbmoitruongxanhbk',
      discord: '',
      linkedin: '',
    },
    activityText: '',
    descText:
      'CLB Môi Trường Xanh ra đời mang trong mình sứ mệnh trở thành đại sứ tuyên truyền và là cầu nối thông tin về lĩnh vực môi trường đến với mọi người. Chúng mình đến từ khoa Môi trường và Tài nguyên. Tuy là một câu lạc bộ trẻ nhưng chúng mình đã có chuỗi các sự kiện thành công và chúng mình luôn nỗ lực nhiều hơn thế nữa. Đến với MOX bạn sẽ nhận được những trải nghiệm đáng nhớ. Hãy ghé gian hàng của MOX bạn nhé!',
  },
  pif: {
    bannerImg: PIFIntro,
    bannerImgPlaceholder: PIFIntroPlaceholder,
    activityImg: PIFActivity,
    activityImgPlaceholder: PIFActivityPlaceholder,
    title: 'PIF',
    name: 'Pay It Forward Club  - PIF',
    descImg: PIFDesc,
    descImgPlaceholder: PIFDescPlaceholder,
    logo: 'pif',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'pif.forum@gmail.com',
      fanpage: 'facebook.com/payitforward.edu.vn',
      discord: '',
      linkedin: '',
    },
    activityText: '',
    descText:
      'Pay It Forward Club - CLB Nghiên Cứu Khoa Học Khoa Điện - Điện tử.\nĐược thành lập vào năm 2009, PIF Club mang sứ mệnh trở thành câu lạc bộ phục vụ cho việc học tập và nghiên cứu khoa học của sinh viên khoa Điện - Điện tử và sinh viên các khoa khác thuộc trường Đại học Bách Khoa - ĐHQG TP.HCM. Câu lạc bộ hoạt động trên tinh thần tự nguyện, chia sẻ và ham học hỏi.\n“Một quả táo cắt làm đôi mỗi người sẽ có một nửa, thế nhưng một kiến thức được truyền đi sẽ nhân rộng gấp nhiều lần.”\n“Pay It Forward” là cách mà CLB hoạt động.',
  },
  'ck-gear': {
    bannerImg: CKGearIntro,
    bannerImgPlaceholder: CKGearIntroPlaceholder,
    activityImg: CKGearActivity,
    activityImgPlaceholder: CKGearActivityPlaceholder,
    title: 'PIF',
    name: 'CK GEAR Team',
    descImg: CKGearDesc,
    descImgPlaceholder: CKGearDescPlaceholder,
    logo: 'ck-gear',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'ckgearteam.hcmut@gmail.com',
      fanpage: 'facebook.com/fme.ckgearteam',
      discord: '',
      linkedin: '',
    },
    activityText: '',
    descText:
      'Chúng mình đến từ Đội Xung Kích Khoa Cơ Khí - CK GEAR Team, là đơn vị tổ chức sự kiện Khoa Cơ Khí, Trường ĐHBK-TP.HCM. Các sự kiện nhà GEAR diễn ra không chỉ để tạo ra một sân chơi lành mạnh mà qua đó còn giúp các bạn cải thiện thêm nhiều kĩ năng mềm từ cách tổ chức tới việc phát triển bản thân khác như làm MC hoặc xử lý tình huống bất ngờ xảy ra khi chạy một sự kiện. Quan trọng chính là GẮN KẾT. Mỗi mảnh ghép đại gia đình áo Cam sẽ được trải nghiệm một môi trường hoạt động sự kiện nghiêm túc: "Làm hết sức, chơi hết mình".',
  },
  'vio-tech': {
    bannerImg: VioIntro,
    bannerImgPlaceholder: VioIntroPlaceholder,
    activityImg: '',
    activityImgPlaceholder: '',
    title: 'Vio',
    name: 'Công nghệ Vio',
    sponsor_type: 'BẠC',
    descImg: VioDesc,
    descImgPlaceholder: VioDescPlaceholder,
    logo: 'vio-tech',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'contact@vioapp.io',
      fanpage: 'facebook.com/datlichvio',
      discord: '',
      linkedin: '',
    },
    activityText:
      'Vio là một nền tảng tổng hợp các lịch trình hoạt động của cá nhân vào một nguồn thông tin duy nhất. Tích hợp chức năng sắp xếp, chỉnh sửa, đặt hẹn thông minh và tối ưu hóa quy trình dễ dàng, khoa học. Vio không chỉ là một ứng dụng, mà còn là một người bạn đồng hành, trợ lý riêng về quản lý lịch trình thông minh',
    descText: 'Sống đơn giản - Vui trọn vẹn 💜',
  },
  'king-car': {
    bannerImg: KingCarIntro,
    bannerImgPlaceholder: KingCarIntroPlaceholder,
    activityImg: '',
    activityImgPlaceholder: '',
    title: 'KingCar',
    name: 'King Car Việt Nam',
    sponsor_type: 'ĐỒNG',
    descImg: KingCarDesc,
    descImgPlaceholder: KingCarDescPlaceholder,
    logo: 'king-car',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'marketing@kingcar.vn',
      fanpage: 'facebook.com/TrasuaMrBrown',
      discord: '',
      linkedin: '',
    },
    activityText:
      'Tập đoàn King Car duy trì ba nguyên tắc chính: Sản xuất, Bồi dưỡng, Tự nhiên. Tập đoàn được thành lập bắt đầu từ sản xuất bình xịt côn trùng, sau đó mở rộng kinh doanh sang ngành thực phẩm và đồ uống, tiếp theo là các lĩnh vực khác như nuôi cấy hoa lan, nuôi trồng thủy sản và công nghệ sinh học. Trong những năm gần đây, Tập đoàn King Car đã đạt được rất nhiều sự công nhận của Quốc tế đối với ngành đồ uống có cồn. Tầm nhìn tiên phong và chiến lược quản lý đa dạng đã giúp cho tập đoàn King Car mở rộng từ chính quê hương của mình - Đài Loan vào thị trường toàn cầu.',
    descText:
      'Với chiết xuất 100% trà tự nhiên, Mr. Brown đã chinh phục cả những fan trà sữa khó tính nhất nhờ sự tinh tế của hương vị trà sữa Đài Loan đích thực.',
  },
  '10-education': {
    bannerImg: Education10Intro,
    bannerImgPlaceholder: Education10IntroPlaceholder,
    activityImg: '',
    activityImgPlaceholder: '',
    title: '10 Education',
    name: '10 Education',
    sponsor_type: 'ĐƠN VỊ ĐỒNG HÀNH',
    descImg: Education10Desc,
    descImgPlaceholder: Education10DescPlaceholder,
    logo: '10-education',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: '10edu@gmail.com',
      fanpage: 'facebook.com/10education',
      discord: '',
      linkedin: '',
    },
    activityText:
      '10 Education tiên phong đào tạo các chứng chỉ Tin học quốc tế MOS (MOS Word, Excel, PPT,…) với đầy đủ các phiên bản MOS 2019, MOS 365 Apps mới nhất hiện hành, IC3 GS5, IC3 GS6.., chứng chỉ SAT xét tuyển đầu vào Đại học và Du học, chứng chỉ tiếng Anh IELTS với chất lượng hàng đầu, cam kết chuẩn đầu ra cho học viên',
    descText: '10 EDUCATION - ĐÀO TẠO TIN HỌC MOS/IC3',
  },
  moseasy: {
    bannerImg: MoseasyIntro,
    bannerImgPlaceholder: MoseasyIntroPlaceholder,
    activityImg: '',
    activityImgPlaceholder: '',
    title: 'MOSEASY',
    name: 'MOSEASY',
    sponsor_type: 'ĐƠN VỊ ĐỒNG HÀNH',
    descImg: MoseasyDesc,
    descImgPlaceholder: MoseasyDescPlaceholder,
    logo: 'moseasy',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'moseasy.edu.vn@gmail.com',
      fanpage: 'facebook.com/ThayDangXuanTho',
      discord: '',
      linkedin: '',
    },
    activityText:
      'Làm chủ chứng chỉ MOS với bộ công cụ toàn diện bao gồm hơn 270 video và các bài thực hành chi tiết, trực quan, dễ hiểu, tuân theo các tiêu chí của bài thi MOS. Các khóa học sẽ cung cấp mã thi thử GMETRIX, cho phép bạn thực hành với hệ thống mô phỏng bài thi thử trên phần mềm Gmetrix của Microsoft với 30 lần thi thử. Đặc biệt, bạn sẽ có quyền học trọn đời, chỉ cần mua một lần và sử dụng mãi mãi, học mọi lúc, mọi nơi và có thể xem đi xem lại không giới hạn.',
    descText:
      'Trung tâm tin học văn phòng MOSEASY.EDU.VN - Là đơn vị đào tạo Mos uy tín, chuyên nghiệp. Với kinh nghiệm nhiều năm hoạt động trong lĩnh vực đào tạo, chúng tôi cam kết giúp 100% học viên đạt chứng chỉ',
  },
  fadely: {
    bannerImg: FadelyIntro,
    bannerImgPlaceholder: FadelyIntroPlaceholder,
    activityImg: '',
    activityImgPlaceholder: '',
    title: 'Fadely',
    name: 'Bánh tráng Fadely',
    sponsor_type: 'ĐƠN VỊ ĐỒNG HÀNH',
    descImg: FadelyDesc,
    descImgPlaceholder: FadelyDescPlaceholder,
    logo: 'fadely',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'info@tinhnguyen.com.vn',
      fanpage: 'facebook.com/banhtrangFadely',
      discord: '',
      linkedin: '',
    },
    activityText:
      'Bánh tráng trộn Fadely là sự kết hợp tuyệt vời giữa vị cay của muối và vị dẻo của bánh tráng tạo nên một hương vị thơm ngon, khó cưỡng lại. Với quy trình sản xuất nghiêm ngặt và đạt chứng nhận vệ sinh an toàn thực phẩm, Fadely đảm bảo an toàn và chất lượng cho người tiêu dùng.',
    descText:
      'Là món ăn tuổi thơ của bao thế hệ, Bánh Tráng Trộn Fadely không chỉ gợi nhớ về những ký ức tươi đẹp mà còn mang đến một trải nghiệm ẩm thực độc đáo với sự kết hợp tuyệt vời từ bánh tráng, muối chấm, tỏi phi, sa tế cùng vô số nguyên liệu thơm ngon đầy sáng tạo. Với quy trình sản xuất nghiêm ngặt và đạt chứng nhận vệ sinh an toàn thực phẩm, Fadely đảm bảo mỗi sản phẩm đến tay khách hàng đều đạt chất lượng tuyệt hảo và an toàn.',
  },
  koikeya: {
    bannerImg: KoikeyaIntro,
    bannerImgPlaceholder: KoikeyaIntroPlaceholder,
    activityImg: '',
    activityImgPlaceholder: '',
    title: 'Koikeya',
    name: 'Koikeya',
    sponsor_type: 'ĐƠN VỊ ĐỒNG HÀNH',
    descImg: KoikeyaDesc,
    descImgPlaceholder: KoikeyaDescPlaceholder,
    logo: 'koikeya',
    logoStyles: 'w-fit max-w-[492px] max-h-[120px]',
    contact: {
      email: 'kkyvn-customer@koike-ya.com',
      fanpage: 'facebook.com/Karamucho.Vn',
      discord: '',
      linkedin: '',
    },
    activityText:
      'Với dây chuyền sản xuất tiên tiến cùng đội ngũ chuyên gia hàng đầu đến từ Nhật Bản, Koikeya Việt Nam tự tin với sứ mệnh mang những sản phẩm chất lượng đỉnh cao đến người tiêu dùng Việt. Đó sẽ là những sản phẩm sở hữu nhiều hương vị thơm ngon độc đáo, đặc biệt là vị cay ngon đậm đà chưa từng được trải nghiệm tại thị trường Việt. Hơn hết thảy, đó còn là những sản phẩm của niềm vui tận hưởng cuộc sống, kết nối người thân và bạn bè, cho những khoảnh khắc bên nhau luôn đầm ấm và ngập tràn cảm hứng.',
    descText:
      'KARAMUCHO, thương hiệu bánh snack CAY bán chạy hàng đầu Nhật Bản, và được yêu chuộng đặc biệt tại nhiều thị trường lớn trên thế giới, nay đã đến Việt Nam.',
  },
  edu2review: {
    bannerImg: Edu2ReviewIntro,
    bannerImgPlaceholder: Edu2ReviewIntroPlaceholder,
    activityImg: '',
    activityImgPlaceholder: '',
    title: 'Edu2Review',
    name: 'Edu2Review và Studynow',
    sponsor_type: 'ĐƠN VỊ ĐỒNG HÀNH',
    descImg: Edu2ReviewDesc,
    descImgPlaceholder: Edu2ReviewDescPlaceholder,
    logo: 'edu2review',
    logoStyles: 'w-full max-w-[492px]',
    contact: {
      email: 'sale.edu2review@gmail.com',
      fanpage: 'facebook.com/Edu2Review.Study.Abroad',
      discord: '',
      linkedin: '',
    },
    activityText:
      'Edu2Review mang lại giá trị cho học viên thông qua sự tin cậy với hơn 40.000 đánh giá được xác thực, giúp tiết kiệm thời gian tìm kiếm thông tin và mang đến sự tiện lợi khi dễ dàng tra cứu, so sánh các khóa học giữa các đơn vị đào tạo, đồng thời đặt chỗ khóa học ngay tức thì. Đối với các trung tâm, Edu2Review giúp tạo lập và quảng bá thương hiệu một cách minh bạch, đồng thời thúc đẩy hiệu quả và gia tăng doanh thu.',
    descText:
      'Tự hào là thương hiệu đầu tiên thuộc hệ sinh thái các thương hiệu đánh giá tín nhiệm giáo dục, Edu2Review quyết tâm cống hiến cho xã hội với sứ mệnh là “Choose the best Education – Giúp học viên lựa chọn dịch vụ giáo dục phù hợp nhất”.\nThông qua việc tổng hợp các đánh giá khách quan, đa chiều về chất lượng giảng dạy, cơ sở vật chất, môi trường học tập… tại các đơn vị đào tạo. Edu2Review đã, đang và tiếp tục đồng hành cùng hàng triệu học sinh, sinh viên, người đi làm giúp họ tìm ra được nơi học phù hợp nhất.',
  },
};
