import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { Icon } from '../../../../components';
import BookFairBookInfo from '../../../../components/BookFair/BookInfo';
import MarketplaceSearchBar, {
  DEFAULT_SEARCH_PARAMS as DEFAULT_BOOKFAIR_SEARCH_PARAMS,
} from '../../../../components/BookFair/MarketplaceSearchBar';
import BookFairMenuBox from '../../../../components/BookFair/MenuBox';
import { Page } from '../../../../layout';
import BookFairService from '../../../../service/bookFair.service';
import useBoundStore from '../../../../store';
import { TBookFairSearchParams } from '../../../../types/bookFair';

const BOOK_FAIR_PAGINATION_SIZE = 50;
const BOOK_MARKETPLACE_DEFAULT_PAGE_PARAM = {
  pageNumber: 1,
  pageSize: BOOK_FAIR_PAGINATION_SIZE,
};

export const SCTTMMarketplacePage: FC = () => {
  const currentUser = useBoundStore().user;
  const isUserAuthenticated = useBoundStore().isAuthenticated;

  const bookGridRef = useRef<HTMLUListElement | null>(null);

  const { data: receivedBooksResponse, isSuccess: receivedBooksSuccessfulQuery } = useQuery({
    queryKey: ['getReceivedBooks', isUserAuthenticated],
    queryFn: BookFairService.getReceivedBooks,
    enabled: isUserAuthenticated,
  });

  const [searchParams, setSearchParams] = useState<TBookFairSearchParams>(
    DEFAULT_BOOKFAIR_SEARCH_PARAMS
  );

  const {
    data: allBooksResponse,
    isLoading: isAllBooksQueryLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['getAllBooks', searchParams],
    queryFn: async ({ pageParam = BOOK_MARKETPLACE_DEFAULT_PAGE_PARAM }) =>
      BookFairService.searchByParam(searchParams, pageParam.pageNumber, pageParam.pageSize),
    getNextPageParam: (lastPage, _allPages) =>
      lastPage.pageNumber >= lastPage.pageCount
        ? undefined
        : { pageNumber: lastPage.pageNumber + 1, pageSize: BOOK_FAIR_PAGINATION_SIZE },
    keepPreviousData: true,
  });

  const flatBookData = useMemo(
    () => (allBooksResponse ? allBooksResponse.pages.flatMap((page) => page.books) : []),
    [allBooksResponse]
  );

  /**
   * TODO: There is a compilation error here, which complains
   * that 'onscrollend' doesn't exist on type 'Window'.
   * Should take time to fix this.
   */
  useEffect(() => {
    (window as any).onscrollend = function () {
      hasNextPage && fetchNextPage();
    };
  }, [hasNextPage, fetchNextPage]);

  return (
    <Page title='Danh mục sách'>
      <main className='overflow-x-hidden px-8 py-4 md:py-8 md:px-5 lg:px-10 xl:px-20 3xl:px-[100px]'>
        <section className='flex w-full justify-end md:justify-start'>
          <BookFairMenuBox />
        </section>
        <br />
        {isUserAuthenticated && (
          <>
            <header>
              <h1 className='font-medium md:text-4xl md:font-bold md:text-[#2F327D]'>
                Xin chào, {currentUser.familyAndMiddleName} {currentUser.givenName}
              </h1>
            </header>
            <br />
          </>
        )}
        {receivedBooksSuccessfulQuery && receivedBooksResponse.total > 0 && (
          <section>
            <p className='text-[#19191B] md:text-[#696984]'>
              Chúc mừng, bạn đã nhận được{' '}
              <strong className='text-[#2727FF] md:text-[#19191B]'>
                <u>{receivedBooksResponse.total} quyển sách </u>
              </strong>
            </p>
            <br />
            <ul className='grid grid-cols-2 justify-items-center gap-x-8 md:flex md:gap-x-16'>
              {receivedBooksResponse.books.map((book) => (
                <li key={book.bookReceiptId} className='w-full md:w-auto'>
                  <BookFairBookInfo title={book.title} thumbnail={book.thumbnail} isUserReceived />
                </li>
              ))}
            </ul>
            <br />
          </section>
        )}
        <section>
          <MarketplaceSearchBar onChange={setSearchParams} />
          {!isAllBooksQueryLoading && flatBookData.length === 0 && (
            <div className='flex flex-col items-center gap-y-6'>
              <Icon.NoBookFound className='h-auto w-[80%] sm:w-[60%] md:w-1/3' />
              <p className='text-center text-base font-semibold text-[#0B2878] sm:text-lg md:text-xl'>
                Hệ thống không tìm thấy sách bạn đang tìm kiếm.
                <br />
                Vui lòng tìm kiếm với từ khoá khác.
              </p>
            </div>
          )}
          <ul
            ref={bookGridRef}
            className='grid grid-cols-2 justify-items-center gap-y-8 gap-x-8 md:grid-cols-3 md:gap-x-16 lg:grid-cols-4 xl:grid-cols-5'
          >
            {flatBookData.map(({ bookId, title, thumbnail, totalAmount, receivedAmount }) => (
              <li key={bookId} className='w-full'>
                <BookFairBookInfo
                  title={title}
                  thumbnail={thumbnail}
                  totalAmount={totalAmount}
                  totalReceived={receivedAmount}
                  isUserReceived={false}
                />
              </li>
            ))}
          </ul>
        </section>
      </main>
    </Page>
  );
};
