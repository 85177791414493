import { ReactNode } from 'react';

import { Page } from '../../layout';
import Footer from '../Footer';
import LazyLoadImage from '../LazyLoadImage';

type PartnerIntroLayoutProps = {
  children: ReactNode;
  bannerImg: string;
  bannerImgPlaceholder: string;
  title: string;
};

const PartnerIntroLayout = (props: PartnerIntroLayoutProps) => {
  const { children, bannerImg, bannerImgPlaceholder, title } = props;

  return (
    <Page title={title}>
      <main className='with-nav-height show-scrollbar flex flex-col gap-y-5 overflow-y-auto overflow-x-hidden text-[16px] md:text-[14px] lg:gap-y-10 lg:text-[18px] xl:text-[20px] 2xl:gap-y-[54px] 3xl:gap-y-[60px]'>
        <div className='w-full md:mt-10 md:px-12 lg:px-24 2xl:px-32 3xl:px-40'>
          <LazyLoadImage
            className='partners-banner-position z-[1] block aspect-[52/25] object-right md:aspect-[4/1] md:rounded-[20px]'
            src={bannerImg}
            placeHolderSrc={bannerImgPlaceholder}
            alt='tstt_alt'
            objectFit='cover'
          />
        </div>
        <div className='mb-4 px-5 md:mb-0 md:px-0'>{children}</div>
        <Footer />
      </main>
    </Page>
  );
};

export default PartnerIntroLayout;
