import { FC, memo } from 'react';

import PlaceholderThumbnail from '../../assets/images/BookfairPlaceholderThumbnail.jpg';

interface BookFairBookInfoProps {
  thumbnail?: string;
  title: string;
  totalAmount?: number;
  totalReceived?: number;
  isUserReceived: boolean;
}

const BookFairBookInfo: FC<BookFairBookInfoProps> = ({
  thumbnail = PlaceholderThumbnail,
  title,
  totalAmount = 0,
  totalReceived = 0,
  isUserReceived,
}) => {
  return (
    <article className='flex w-full flex-col gap-4 md:w-52'>
      <img
        src={thumbnail}
        alt={title}
        className='aspect-[160/250] w-full rounded-[20px] object-cover shadow-[0px_7px_14px_rgba(6,7,13,0.05)] transition-all duration-500 hover:scale-105'
      />
      <section>
        <h2 className='text-base font-semibold text-[#333C4E] md:text-2xl'>{title}</h2>
        <div>
          {isUserReceived ? (
            <p className='uppercase text-[#1877F2]'>Đã nhận</p>
          ) : (
            <>
              <p className='text-sm font-medium text-[#1877F2]'>
                Còn lại: {totalAmount - totalReceived}
              </p>
            </>
          )}
        </div>
      </section>
    </article>
  );
};

export default memo(BookFairBookInfo);
